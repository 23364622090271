
import { defineComponent } from "vue";
import BadgePopup from "./BadgePopup.vue"; // Angepasster Pfad

export default defineComponent({
  name: "WIPFeatures",
  components: { BadgePopup },
  data() {
    return {
      welcomePopout: localStorage["welcomePopout_wip"] === "true",
      isBadgePopupOpen: false,
    };
  },
  methods: {
    openBadgePopup() {
      this.isBadgePopupOpen = true;
    },
  },
  watch: {
    welcomePopout() {
      localStorage["welcomePopout_wip"] = this.welcomePopout;
    },
  },
});
