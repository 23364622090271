
  import { defineComponent } from "vue";
  import { badges } from "@/constants/badges";
  
  export default defineComponent({
    name: "BadgePopup",
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        badges,
      };
    },
    methods: {
      closePopup() {
        this.$emit("update:isOpen", false);
      },
    },
  });
  